import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import * as Sentry from "@sentry/remix"
import { useEffect } from "react"
import { hydrate } from "react-dom"

Sentry.init({
  dsn: window.ENV?.PUBLIC_SENTRY_DSN,
  environment: window.ENV?.PUBLIC_SENTRY_ENVIRONMENT,
  includeLocalVariables: true,
  tracesSampleRate: 0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches,
      ),
    }),
  ],
})

hydrate(<RemixBrowser />, document)
